import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';

import useAccountPlaylistPitch from '@/hooks/playlist/useAccountPlaylistPitches';
import { PlaylistPitchAccountItemsModel } from '@/models/Playlist';

import { PlaylistingDefaultCard } from '../cards/PlaylistingDefaultCard';
import Loading from '../utility/Loading';
import ButtonComponent from '../utility/microcomponents/Button';
import CampaignStatus from '../utility/statuses/CampaignStatus';

const CampaignsPlaylists = () => {
  const navigate = useNavigate();

  const [pitches, setPitches] = useState<PlaylistPitchAccountItemsModel[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [loadMoreToken, setLoadMoreToken] = useState<string>();
  const [continuationToken, setContinuationToken] = useState<string>();

  const lastItemRef = useRef<HTMLDivElement>(null);

  const handleClick = (item: PlaylistPitchAccountItemsModel) => {
    navigate(`/playlisting/playlist-pitch/${item.id}`);
  };

  const { accountPlaylistPitch, accountPlaylistPitchIsLoading } = useAccountPlaylistPitch({
    continuationToken: loadMoreToken,
    ...(isInitialLoad === true && { isInitial: true }),
  });

  useEffect(() => {
    if (!accountPlaylistPitch) return;

    if (accountPlaylistPitch.continuationToken) {
      setContinuationToken(encodeURIComponent(accountPlaylistPitch.continuationToken));
    } else {
      setContinuationToken('');
    }
    setPitches((value) => {
      const filteredPitches = value.filter((pitch) => !accountPlaylistPitch.items.some((item) => item.id === pitch.id));
      return [...filteredPitches, ...accountPlaylistPitch.items];
    });
    setIsInitialLoad(false);
  }, [accountPlaylistPitch, loadMoreToken]);

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!accountPlaylistPitch?.itemCount || pitches.length === accountPlaylistPitch?.itemCount) return;
      if (entries[0].isIntersecting && pitches.length < accountPlaylistPitch?.itemCount) {
        setLoadMoreToken(continuationToken);
      }
    },
    [continuationToken, accountPlaylistPitch?.itemCount, pitches.length]
  );

  useEffect(() => {
    if (lastItemRef.current) {
      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        canPage(entries);
      });

      observer.observe(lastItemRef.current);

      return () => observer.disconnect();
    }
  }, [canPage, lastItemRef, continuationToken]);

  return (
    <>
      {isInitialLoad && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {/* {!isInitialLoad && pitches && pitches.length === 0 && (
        <div className="text-center card mt10">
          <h5 data-testid="no-pitches-available">No playlist pitches yet</h5>
        </div>
      )} */}
      {!isInitialLoad && pitches && pitches.length === 0 && <PlaylistingDefaultCard />}
      {!isInitialLoad &&
        pitches.length > 0 &&
        pitches?.map((item, index) => (
          <ButtonComponent
            isCustom={true}
            onClick={() => {
              handleClick(item);
            }}
            key={item.id}
            className="card-inner w100p mb8 p16 list-item-parent"
            style={{ '--animation-number': `${index}` } as React.CSSProperties}
          >
            <div
              className="d-flex list-item campaign flex-wrap w100p"
              ref={item === pitches[pitches.length - 1] ? lastItemRef : null}
            >
              <img src={item.track?.image ? item.track?.image : '/images/logos/unhurd-logo.png'} alt="to-do-img" />
              <div className="mt-auto mb-auto text-center">
                <div>
                  <CampaignStatus status={item.status} />
                </div>
                <p className="text-faded small">{format(new Date(item.createdAt), 'dd/MM/yy')}</p>
              </div>
              <div className="flex-grow mt-auto mb-auto text-left">
                <div className="fs-16 fw-bold text-white">{item.name}</div>
                <p className="text-faded small">{item.artist.name}</p>
              </div>
              <p className="text-faded small mt-auto mb-auto ml-auto">{`Submitted to ${
                item.playlists.length
              } ${item.playlists.length === 1 ? 'playlist' : 'playlists'}`}</p>
            </div>
          </ButtonComponent>
        ))}
      {continuationToken !== '' && !isInitialLoad && loadMoreToken && accountPlaylistPitchIsLoading && (
        <div className="text-center mt16">
          <Loading size={'small'} />
        </div>
      )}
    </>
  );
};

export default CampaignsPlaylists;
